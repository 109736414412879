<template>
	<div class="flex justify-center items-center flex-1 h-96">
		<Spinner v-if="isLoading"/>
	</div>

</template>

<script>
import Spinner from "@/components/atoms/Loaders/Spinner/Spinner";
export default {
  components: {
		Spinner
  },
  data () {
    return {
      isLoading: false,
    }
  },
	async created() {
    try {
      this.isLoading = true;
      const {validateSSO} = await this.actions.user.validateSSO({
        code: this.$route.query?.code,
        responseType: this.$route.query?.response_type,
        siteId: this.$route.query?.s
      });

      console.log(validateSSO, 'validateSSO');

      await this.actions.loginWithToken({token: validateSSO?.ssoToken, userId: validateSSO?.id});
      this.actions.alert.showSuccess({message: 'Logged In Successfully!'});
      this.$router.push('/dashboard')
    } catch (e) {
      console.log(e)
    } finally {
      this.isLoading = false;
    }

	}
}
</script>
